import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'

import projectStorage from './reducers/rootReducer'
import Spinner from "./components/spinner";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<React.StrictMode>
    <Provider store={projectStorage.store}>
        <PersistGate loading={<Spinner />} persistor={projectStorage.persistor}>
            <App/>
        </PersistGate>
    </Provider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
